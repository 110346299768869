import React from 'react';
const Explore = () => {
  return (
    <div className="container-fluid">
      <h1>Explore</h1>
    </div>
  );
};

export default Explore;
