import React from 'react';
const Blog = (): JSX.Element => {
  return (
    <div>
      <h1>Blog</h1>
    </div>
  );
};

export default Blog;
