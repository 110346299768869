const data = {
  //width of image for the number of columns on screen
  IMAGE_WIDTH_3COLUMNS: 416,
  IMAGE_WIDTH_2COLUMNS: 463,
  IMAGE_WIDTH_1COLUMN: 760,

  //for the related images section on opening a modal
  RELATED_WIDTH_3COLUMNS: 376,
  RELATED_WIDTH_2COLUMNS: 463,

  //grid properties
  COLUMN_HEIGHT: 2600,
  ROW_GAP: 10,
  COLUMN_GAP: 24,

  //media queries: when to make the grid columns = 2 or = 1
  SCREEN_WIDTH_3COLUMNS: 9999,
  SCREEN_WIDTH_2COLUMNS: 975,
  SCREEN_WIDTH_1COLUMN: 765,

  SCREEN_WIDTH_RELATED_3COLUMNS: 9999,
  SCREEN_WIDTH_RELATED_2COLUMNS: 1000,

  HEADER_HEIGHT: 62,

  FULL_MODAL_SCREEN_WIDTH: '865px',
};

export default data;
